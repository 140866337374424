import React from 'react';
import PropTypes from 'prop-types';
import { Box, Table, TableRow, TableCell, TableBody, Text } from 'grommet';
import { storyblokEditable, StoryblokComponent } from 'gatsby-source-storyblok';

import DynamicIcon from '../Icons/DynamicIcon';
import TextTitle from '../TextTitle';

const LabelComponent = ({ children, size, textStyle }) => {
  if (textStyle === 'heading' || !textStyle) {
    return <TextTitle size={size ?? 'medium'}>{children}</TextTitle>;
  }
  return (
    <Text as="dd" size={size}>
      {children}
    </Text>
  );
};

const DynamicIconFeatureTable = ({ blok, ...rest }) => {
  const {
    list,
    cellPaddingVertical,
    cellPaddingHorizontal,
    cellVerticalAlignment = 'middle',
    noWrap = false,
  } = blok;
  return (
    <Table {...storyblokEditable(blok)} plain>
      <TableBody>
        {list.map((iconFeature, i) => {
          return (
            <TableRow key={i}>
              {(iconFeature.icon || iconFeature.label) && (
                <TableCell
                  pad={{
                    vertical: cellPaddingVertical,
                    right: cellPaddingHorizontal,
                  }}
                  style={{
                    whiteSpace: noWrap ? 'nowrap' : 'normal',
                  }}
                  verticalAlign={cellVerticalAlignment}
                >
                  <Box gap="medium" align="center" direction="row">
                    {iconFeature.icon && (
                      <DynamicIcon
                        type={iconFeature.icon}
                        size={iconFeature.size}
                        color={'black'}
                        strokeWidth={0}
                      />
                    )}
                    <TextTitle as="dt" size={iconFeature.labelSize ?? 'medium'}>
                      {iconFeature.label}
                    </TextTitle>
                  </Box>
                </TableCell>
              )}
              <TableCell
                pad={{
                  vertical: cellPaddingVertical,
                  horizontal: cellPaddingHorizontal,
                }}
                align="start"
                verticalAlign={cellVerticalAlignment}
              >
                <LabelComponent
                  size={iconFeature.labelSize ?? 'small'}
                  textStyle={iconFeature.textStyle}
                >
                  {iconFeature.content?.length > 0 ? (
                    <StoryblokComponent
                      blok={iconFeature.content[0]}
                      {...rest}
                    />
                  ) : (
                    iconFeature.text
                  )}
                </LabelComponent>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

DynamicIconFeatureTable.propTypes = {
  blok: PropTypes.shape({
    list: PropTypes.array,
    cellPaddingVertical: PropTypes.string,
    cellPaddingHorizontal: PropTypes.string,
  }),
};

export default DynamicIconFeatureTable;
